import React from "react"
import { StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image"
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import Layout from "../components/layout"
import Banner from "../components/banner"
import { useStaticQuery, graphql, withPrefix, Link } from "gatsby"
import Seo from "../components/seo"

      



  

const caseStudyDetail = ( {data} ) => {
  const images_path = [];
  const rot_path = data.allWpCaseStudy.edges
 // console.log('original',rot_path);
  rot_path.map( (single) =>  {
    images_path.push({original : single.node.caseStudy.featuredImage.localFile.childImageSharp.fluid.originalImg})
//console.log('single',single)
  })
  //console.log('after',images_path);
//console.log('case',data.allWpCaseStudy.edges )

return (


    <Layout>



<ImageGallery items={images_path}   showFullscreenButton = {false} autoPlay = {true} showNav = {false} showPlayButton = {false} showBullets = {true}  showThumbnails = {false}/>


<section className = "about_client">

<div className = "client_content">

        <h2>about client</h2>
        <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
         standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
          specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially 
          unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
           recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.


        </p>
        <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
         standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
          specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially 
          unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
           recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.


        </p>

</div>

<div className = "client_img">

  
<StaticImage
        src="../images/pro.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

</div>



</section>




<section className = "services_client">

<div className="services_img">

  
<StaticImage
        src="../images/client_Services.png"
        quality = "90"
       
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

</div>


<div className="services_details">

<h2>our services </h2>
<h2>to client</h2>

<div className="service_offer">

<ul>

    <li>
    <StaticImage
        src="../images/store_migration.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Store Migration</h4>

    </li>
    <li>
    <StaticImage
        src="../images/store_setup.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Store Setup</h4>

    </li>
    <li>
    <StaticImage
        src="../images/theme_customization.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Theme Customization</h4>

    </li>
    <li>
    <StaticImage
        src="../images/demand_development.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>On-Demand Development</h4>

    </li>

    <li>
    <StaticImage
        src="../images/store_front.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Storefront Customization</h4>

    </li>

    <li>
    <StaticImage
        src="../images/ui_ux.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>UI/UX Design</h4>

    </li>

    
    <li>
    <StaticImage
        src="../images/product_flow.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Products Flow Designs</h4>

    </li>

    <li>
    <StaticImage
        src="../images/api_integration.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>APIs and Integrations</h4>

    </li>

    <li>
    <StaticImage
        src="../images/store_maintance.png"
        quality = "90"
      
        placeholder="tracedSVG"
         alt="A Gatsby astronaut" />

         <h4>Store Maintenance</h4>

    </li>




</ul>


</div>



</div>

</section>

<section className = "complete_package">

<StaticImage
        src="../images/complete_package.png"
        quality = "90"
      
        placeholder="tracedSVG"
        layout = "fullWidth"
         alt="A Gatsby astronaut" />


</section>

<section className = "service_types increased">


    <div className = "container">

      <div className = "serv_wrapper">


      
     

  <div className = "serv_box" >


  <StaticImage
        src="../images/in_2.png"
        quality = "90"
        width = "140"
        placeholder="tracedSVG"
      
         alt="A Gatsby astronaut" />


      <div className ="serv_info">

        <h3>increase by</h3>
        <h1>46%</h1>
        <h4>site engagement</h4>

      </div>
    </div>


    <div className = "serv_box" >


<StaticImage
      src="../images/in_3.png"
      quality = "90"
      width = "140"
      placeholder="tracedSVG"
    
       alt="A Gatsby astronaut" />


    <div className ="serv_info">

      <h3>increase by</h3>
      <h1>91%</h1>
      <h4>conversion rate</h4>

    </div>
  </div>

  <div className = "serv_box" >


<StaticImage
      src="../images/in_1.png"
      quality = "90"
      width = "140"
      placeholder="tracedSVG"
    
       alt="A Gatsby astronaut" />


    <div className ="serv_info">

      <h3>increase by</h3>
      <h1>20%</h1>
      <h4>churn rate</h4>

    </div>
  </div>
      
</div>
</div>
</section>





    </Layout>

)


}


export const case_images = graphql`
query MyQuery {
  allWpCaseStudy {
    edges {
      node {
        caseStudy {
         
          featuredImage {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  }
}



`;
export default caseStudyDetail;